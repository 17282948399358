import img_a from './images/img_a_deyenerys.svg';
import img_b from './images/img_b_zabava.svg';
import img_c from './images/img_c_marrypoppins.png';
import img_d from './images/img_d_barbie.svg';
import img_e from './images/img_e_carrera.png';
import img_f from './images/img_f_amelie.png';
import img_g from './images/img_g_ellie.png';
import img_h from './images/img_h_gayka.png';

export type TResult = {
  title?: string;
  text?: string[];
  img?: string;
  alt?: string;
};

export const RESULT = {
  a: {
    title: 'Дракон выбрал хозяйку',
    text: [
      'Как Дейенерис Бурерождённая, вы знаете себе цену и\u00A0никогда не\u00A0сойдёте со\u00A0своего пути. Сложно представить более подходящий «транспорт» для\u00A0великой личности!',
      'Но если вдруг решите покорить Яндекс Про, пассажирам всё же может понадобиться сиденье.',
    ],
    img: img_a,
    alt: 'Дейенерис с драконом',
  },
  b: {
    title: 'Предъявите лицензию пилота-морехода',
    text: [
      'Вы\u00A0— свободолюбивая и\u00A0целеустремленная натура. Если\u00A0понадобится, все Полканы будут плясать под\u00A0вашу дудку.',
      'Ведите Летучий корабль как настоящий капитан судна\u00A0— на\u00A0пути могут быть воздушные ямы!',
    ],
    img: img_b,
    alt: 'Забава и Летучий корабль',
  },
  c: {
    title: 'Обещают Ветер перемен. Зонтик при\u00A0вас?',
    text: [
      'Безупречные манеры, страсть к\u00A0маленьким приключениям и\u00A0никакой метеозависимости\u00A0— не\u00A0женщина, а\u00A0само совершенство!',
      'Рекомендуем попробовать себя в\u00A0тарифе «Детский».',
    ],
    img: img_c,
    alt: 'Мерри Поппинс с\u00A0зонтиком',
  },
  d: {
    title: 'Розовый Кабриолет мчит к\u00A0закату',
    text: [
      'Привет, Барб… а,\u00A0ой! Обознались. Но\u00A0что-то общее у\u00A0вас есть: укладка всегда идеальная и\u00A0любое дело немедленно удаётся.',
      'В Яндекс Про у\u00A0вас всегда был бы высокий рейтинг и\u00A0прекрасные отзывы от\u00A0пассажиров.',
    ],
    img: img_d,
    alt: 'Барби и кабриолет',
  },
  e: {
    title: 'Врум-врум!',
    text: [
      'Слышите этот звук? Всё верно, голубая Porsche 911 Carrera\u00A0— для той, кто готова быстро идти к\u00A0мечте, даже если для\u00A0этого придётся свернуть с\u00A0привычного пути.',
      'Маршрут до\u00A0Радиатор-Спрингс не\u00A0построим, увы! Но\u00A0можно найти новое призвание с\u00A0Яндекс Про\u00A0— кажется, премиальные тарифы «Ultima» как\u00A0раз для\u00A0вас.',
    ],
    img: img_e,
    alt: 'Porsche 911',
  },
  f: {
    title: 'Дорогу незнакомке на\u00A0Мопеде!',
    text: [
      'Очаровательный смех и\u00A0жажда жизни в\u00A0горящих глазах. «Никогда не\u00A0бойся следовать зову сердца». «Лучшее лекарство\u00A0— делать то, что\u00A0хочется»\u00A0— где‑то мы это уже слышали. И, кажется, это про вас тоже.',
      'Влюбиться можно в\u00A0любой город, не\u00A0только в\u00A0Рим. Многократно проверено водителями «Межгорода»!',
    ],
    img: img_f,
    alt: 'Амели и мопед',
  },
  g: {
    title: 'Есть Волшебные башмачки\u00A0— значит,\u00A0есть\u00A0дорога',
    text: [
      'А\u00A0если есть дорога, значит, по\u00A0ней можно ехать\u00A0— такие законы работают не\u00A0только в\u00A0Изумрудном городе.',
      'В\u00A0Яндекс Про проще: чтобы вернуться домой, не\u00A0надо избавляться от\u00A0злых ведьм. А\u00A0если включить режим «Домой», ещё и\u00A0заказы от\u00A0попутчиков найдутся. Только львов в\u00A0салоне не\u00A0возите, даже в\u00A0переноске.',
    ],
    img: img_g,
    alt: 'Элли и туфельки',
  },
  h: {
    title: 'От винта!',
    text: [
      'Участливая подруга, для\u00A0которой не\u00A0бывает слишком маленьких дел. Всегда прилетит на\u00A0помощь и\u00A0разрулит любую ситуацию. Сходство со\u00A0смелой и\u00A0ответственной Гаечкой очевидно!',
      'Если взмыть в\u00A0воздух на\u00A0аэроплане пока не\u00A0готовы, приходите в\u00A0Яндекс Про\u00A0— вам будут рады.',
    ],
    img: img_h,
    alt: 'Гаечка и аэроплан',
  },
};
