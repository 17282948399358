export default function sendEventToCounters(params) {
  const { action, label } = params;

  // eslint-disable-next-line
  // console.log('GOAL: ', `yaPro_${action}_${label}`, params);

  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(91200535, 'reachGoal', `yaPro_${action}_${label}`);
  }
}
