import cn from 'classnames';
import styles from './Pagination.module.scss';
import { DATA } from '../../pages/Game/data';
import { PaginationItem } from './components/PaginationItem';
import { useEffect, useState } from 'react';

type TProps = {
  step: number;
  classes?: string;
};

export const Pagination = ({ step, classes }: TProps) => {
  const length = DATA[0].questions.length + 1;
  const indexes = [...Array(length).keys()];
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrent(step);
    }, 500);
  }, [step]);

  return (
    <div className={cn(classes, styles.pagination, 'flex')}>
      {indexes.map((i) => {
        return <PaginationItem isActive={i <= current} key={i} index={i} />;
      })}
    </div>
  );
};
