import cn from 'classnames';
import styles from './BtnReplay.module.scss';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '@/src/store/slices/stepSlice';
import { setCurrentBranch } from '@/src/store/slices/branchSlice';
import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';
import { clearAnswers } from '@/src/store/slices/answersSlice';
import sendEventToCounters from '@/src/countersEvents.js';

type TProps = {
  text?: string;
  classes?: string;
};

export const BtnReplay = ({ text = 'Пройти заново', classes }: TProps) => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();

  const sendAnalytics = (action = 'results', label = 'again') => {
    sendEventToCounters({ action, label });
  };

  const handleResetGame = () => {
    dispatch(setCurrentStep(0));
    dispatch(setCurrentBranch(0));
    dispatch(clearAnswers());

    changePage('branches');
    sendAnalytics();
  };

  return (
    <button
      className={cn(styles.btn_replay, classes)}
      onClick={handleResetGame}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 4V10H17"
          stroke="#21201F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 20V14H7"
          stroke="#21201F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.51 9C4.01717 7.56678 4.87913 6.2854 6.01547 5.27542C7.1518 4.26543 8.52547 3.55976 10.0083 3.22426C11.4911 2.88875 13.0348 2.93434 14.4952 3.35677C15.9556 3.77921 17.2853 4.56471 18.36 5.64L23 10M1 14L5.64 18.36C6.71475 19.4353 8.04437 20.2208 9.50481 20.6432C10.9652 21.0657 12.5089 21.1112 13.9917 20.7757C15.4745 20.4402 16.8482 19.7346 17.9845 18.7246C19.1209 17.7146 19.9828 16.4332 20.49 15"
          stroke="#21201F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {text}
    </button>
  );
};
