import img1 from './images/img1.svg';
import img1_xs from './images/img1_xs.svg';
import img2 from './images/img2.svg';
import img2_xs from './images/img2_xs.svg';
import img3 from './images/img3.svg';
import img3_xs from './images/img3_xs.svg';
import img4 from './images/img4.svg';
import img4_xs from './images/img4_xs.svg';

export const BRANCHES = [
  {
    branch_id: 0,
    branch_analytics_id: 'ownWay',
    title: 'Самой выбирать свой путь',
    description: [
      'В\u00A0этом суть сотрудничества с\u00A0Яндекс Про: водители сами решают, когда\u00A0выйти на\u00A0линию, а\u00A0когда заняться своими делами.',
    ],

    img: img1,
    img_xs: img1_xs,
    alt: 'Навигатор и часы',
  },
  {
    branch_id: 1,
    branch_analytics_id: 'perfect',
    title: 'Становиться лучше',
    description: [
      'На\u00A0дороге важно стремиться к\u00A0профессионализму. Чёткие стандарты сервиса и\u00A0база знаний помогают водителям повышать своё мастерство.',
    ],
    img: img2,
    img_xs: img2_xs,
    alt: 'Девушка и книги',
  },
  {
    branch_id: 2,
    branch_analytics_id: 'newHorizon',
    title: 'Открывать новые горизонты',
    description: [
      'Жизнь слишком интересна, чтобы упускать возможности. Водители общаются друг с\u00A0другом, заводят новые знакомства и\u00A0каждый день лучше узнают любимый город.',
    ],
    img: img3,
    img_xs: img3_xs,
    alt: 'Девушка смотрит на пейзаж',
  },
  {
    branch_id: 3,
    branch_analytics_id: 'usefull',
    title: 'Выручать людей',
    description: [
      'Подбросить кого-то в гости к\u00A0друзьям, помочь забрать детей из\u00A0секции или\u00A0успеть на\u00A0поезд к\u00A0родителям\u00A0— в\u00A0Яндекс Про видят большой смысл даже в\u00A0короткой поездке.',
    ],
    img: img4,
    img_xs: img4_xs,
    alt: 'Люди садятся в такси',
  },
];
