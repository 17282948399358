import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import cn from 'classnames';
import sendEventToCounters from '@/src/countersEvents.js';

import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { WebtiseLink } from '@/src/components/WebtiseLink/WebtiseLink';
import styles from './Home.module.scss';

import girl from './images/girl.svg';
import girl_xs from './images/girl_xs.svg';
import city from './images/city.svg';
import license from './images/license.svg';

export const Home = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'landing', label });
  };

  const clickStartGameHandler = () => {
    changePage('branches');
    sendAnalytics('goAhead');
  };

  return (
    <div className={cn(styles.home, 'appear')}>
      <div className={styles.main}>
        <div className={cn(styles.header_container, 'container')}>
          <Header />
        </div>
        <div className={cn(styles.main_container, 'container')}>
          <div className={styles.main_wrapper}>
            <h1 className={cn(styles.title_main, 'title_1')}>
              Героини за&#160;рулём
            </h1>
            <div className={styles.main_content}>
              <div className={styles.caption}>
                <p>
                  Волшебные сказки и&#160;легенды, а&#160;заодно и&#160;их
                  экранизации&#160;— это всегда отражение жизни. Неудивительно,
                  что в&#160;них героини летают на&#160;драконах, управляют
                  пиратскими и&#160;космическими кораблями — женщине всё
                  по&#160;плечу. А&#160;уж городское такси&#160;—
                  тем&#160;более.
                </p>
                <p>
                  Давайте узнаем, какой транспорт из&#160;историй
                  про&#160;отважных женщин подошёл бы вам&#160;— ответьте
                  на&#160;4 простых вопроса. Заодно мы расскажем вам маленькие
                  истории о&#160;реальных героинях в&#160;такси
                  от&#160;приложения Яндекс Про и&#160;подскажем, какие плюсы
                  они для&#160;себя нашли в&#160;этом деле.
                </p>
              </div>
              <button
                className={cn(styles.start_btn, 'btn', 'btn_l')}
                onClick={clickStartGameHandler}
              >
                Поехали
              </button>
            </div>
          </div>
          <div className={styles.img_city}>
            <img src={city} width={1298} height={842} alt="Город" />
          </div>
          <div className={styles.img_girl}>
            <picture>
              <source media="(max-width: 699px)" srcSet={girl_xs} />
              <img
                src={girl}
                width={1051}
                height={410}
                alt="Девушка в машине"
              />
            </picture>
          </div>
        </div>
      </div>
      <div className={styles.cta}>
        <div className={cn(styles.cta_container, 'container')}>
          <div className={cn(styles.cta_wrapper)}>
            <div className={styles.cta_content}>
              <h2 className={cn(styles.cta_title, 'title_3')}>
                Вдохновились этими женщинами?
              </h2>
              <p className={styles.cta_text}>
                Кстати, вы можете стать одной из&#160;них&#160;— нужны
                действующие права категории&#160;B и&#160;не&#160;менее
                3&#160;лет водительского стажа. С&#160;остальным
                мы&#160;поможем!
              </p>
              <WebtiseLink
                classes={`${styles.join_link} ${styles.join_link_dt}`}
                analytics_action="landing"
                analytics_label="howPartner"
              />
            </div>
            <div className={styles.cta_requirements}>
              <div
                className={cn(
                  styles.cta_requirement,
                  styles.cta_requirement_exp,
                )}
              >
                <p>стаж от 3-х лет</p>
              </div>
              <div
                className={cn(
                  styles.cta_requirement,
                  styles.cta_requirement_dark,
                  styles.cta_requirement_license,
                )}
              >
                <p>права категории В</p>
              </div>
            </div>
            <div className={styles.img_license}>
              <img src={license} width={361} height={363} alt="Лицензия" />
            </div>
            <WebtiseLink
              classes={`${styles.join_link} ${styles.join_link_mob}`}
              analytics_action="landing"
              analytics_label="howPartner"
            />
          </div>
        </div>
      </div>
      <div className={cn(styles.footer_container, 'container')}>
        <Footer classes={styles.footer} />
      </div>
    </div>
  );
};
