import cn from 'classnames';
import styles from './HeaderWithPagination.module.scss';
import { Pagination } from '../Pagination';

type TProps = {
  title: string;
  step: number;
  classes?: string;
  shouldBlink?: boolean;
};

export const HeaderWithPagination = ({
  title,
  classes,
  step,
  shouldBlink,
}: TProps) => {
  return (
    <div
      className={cn(classes, styles.header_with_pagination, 'title_2', 'flex')}
    >
      <h2 className={cn(styles.title, shouldBlink ? 'appear' : '')}>{title}</h2>
      <Pagination classes={styles.pagination} step={step} />
    </div>
  );
};
