import cn from 'classnames';
import sendEventToCounters from '@/src/countersEvents.js';
import hh_logo from '../../images/hh_logo.svg';
import styles from './Footer.module.scss';
import { HH_COMPANY_PAGE_LINK } from '../../consts.js';

type TProps = {
  classes?: string;
};

export const Footer = ({ classes }: TProps) => {
  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'landing', label });
  };

  return (
    <footer className={cn(styles.footer, classes)}>
      <div className={cn(styles.footer_container, 'flex')}>
        <div className={cn(styles.copyright, 'flex')}>
          <p className={styles.brand}>© Яндекс. Такси</p>
          <div className={cn(styles.hh, 'flex')}>
            <img className={styles.hh_logo} src={hh_logo} alt="Логотип HH.ru" />
            <p className={styles.hh_text}>Бренд-центр</p>
          </div>
        </div>
        <ul className={cn(styles.links, 'flex')}>
          <li className={styles.link_item}>
            <a
              className={styles.link}
              href={HH_COMPANY_PAGE_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() => sendAnalytics('vacancies')}
            >
              Вакансии
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
