import { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import cn from 'classnames';
import { BRANCHES } from './data';
import { Card } from '@/src/components/Card';
import { HeaderWithPagination } from '@/src/components/HeaderWithPagination';
import { Footer } from '@/src/components/Footer';
import styles from './Branches.module.scss';
import 'swiper/css';
import { useAppSelector } from '@/src/hooks';
import { currentStepSelector } from '@/src/store/selectors/step';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '@/src/store/slices/stepSlice';
import { setCurrentBranch } from '@/src/store/slices/branchSlice';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import sendEventToCounters from '@/src/countersEvents.js';

export const Branches = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();
  const currentStep = useAppSelector(currentStepSelector);

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const isMobile = useMediaQuery('(max-width: 1019px)');
  const isSmallHeight = useMediaQuery(
    '(min-width: 1019px) and (max-height: 800px)',
  );

  const sendAnalytics = (label: string) => {
    sendEventToCounters({
      action: `quest${currentStep + 1}`,
      label,
    });
  };

  const handleClickOnBranch = (i: number, branchAnalyticsId: string) => {
    dispatch(setCurrentBranch(i));
    setSelectedAnswer(i);
    sendAnalytics(branchAnalyticsId);
  };

  const handleClickGoToGame = () => {
    dispatch(setCurrentStep(currentStep + 1));
    changePage('game');
  };

  const title = `Прислушайтесь к\u00A0внутреннему навигатору.
    Что\u00A0для\u00A0вас главное в\u00A0жизни?`;

  return (
    <div className={cn(styles.branches, 'appear')}>
      <div className={cn(styles.main_container, 'container')}>
        <HeaderWithPagination
          classes={styles.header}
          title={title}
          step={currentStep}
        />
        {(isMobile || isSmallHeight) && (
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            watchOverflow={true}
            slidesPerView={1.03}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
          >
            {BRANCHES.map((branch, i) => {
              return (
                <SwiperSlide key={i}>
                  <Card
                    key={i}
                    cardClickHandler={() =>
                      handleClickOnBranch(i, branch.branch_analytics_id)
                    }
                    btnClickHandler={handleClickGoToGame}
                    isSelected={Boolean(
                      selectedAnswer !== null && selectedAnswer === i,
                    )}
                    isDisabled={Boolean(
                      selectedAnswer !== null && selectedAnswer !== i,
                    )}
                    index={i + 1}
                    data={branch}
                    hasOutline
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {!isMobile && !isSmallHeight && (
          <div className={styles.items}>
            {BRANCHES.map((branch, i) => {
              return (
                <Card
                  key={i}
                  cardClickHandler={() =>
                    handleClickOnBranch(i, branch.branch_analytics_id)
                  }
                  btnClickHandler={handleClickGoToGame}
                  isSelected={Boolean(
                    selectedAnswer !== null && selectedAnswer === i,
                  )}
                  isDisabled={Boolean(
                    selectedAnswer !== null && selectedAnswer !== i,
                  )}
                  index={i + 1}
                  data={branch}
                  hasOutline
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={cn(styles.footer_container, 'container')}>
        <Footer />
      </div>
    </div>
  );
};
