import styles from './Header.module.scss';

import hh_logo from '../../images/hh_logo.svg';
import x from './images/x.svg';
import yandex_pro_logo from './images/yandex_pro_logo.svg';

export const Header = () => {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.logos}>
          <img
            className={styles.hh_logo}
            src={hh_logo}
            width={43}
            height={43}
            alt="Логотип HH.ru"
          />
          <img
            className={styles.x}
            src={x}
            width={16}
            height={17}
            alt="совместно с"
          />
          <img
            className={styles.yandex_pro_logo}
            src={yandex_pro_logo}
            width={218}
            height={37}
            alt="Логотип Яндекс Про"
          />
        </div>
      </header>
    </>
  );
};
