import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  currentStep: number;
}

const initialState: IState = {
  currentStep: 0,
};

const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
  },
});

export const { setCurrentStep } = stepSlice.actions;

export default stepSlice;
