import cn from 'classnames';
import { MouseEvent } from 'react';

export type TCard = {
  title?: string;
  description?: string[];
  img?: string;
  img_xs?: string;
  alt?: string;
};

type TProps = {
  index: number;
  classes?: string;
  isSelected: boolean;
  isDisabled: boolean;
  cardClickHandler: () => void;
  btnClickHandler: () => void;
  data: TCard;
  hasOutline?: boolean;
};

export const Card = ({
  index,
  classes,
  isSelected,
  isDisabled,
  cardClickHandler,
  btnClickHandler,
  data,
  hasOutline = false,
}: TProps) => {
  const { title, description, img, img_xs, alt } = data;

  const handleClickOnCard = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).tagName !== 'BUTTON') {
      cardClickHandler();
    }
  };

  return (
    <div
      className={cn('card', `card--${index}`, classes, {
        'card--selected': isSelected,
        'card--disabled': isDisabled,
        'card--outline': hasOutline,
      })}
      onClick={handleClickOnCard}
    >
      <div className="card__content">
        <h3 className={cn('card__title', 'title_4')}>{title}</h3>
        <div className="card__hidden">
          <div className="card__description">
            {description?.map((string, i) => <p key={i}>{string}</p>)}
          </div>
          <button
            className={cn('card__btn', 'btn', 'btn_black')}
            onClick={btnClickHandler}
          >
            Далее
          </button>
        </div>
      </div>
      <div className="card__img">
        <div className="card__img_wrapper">
          <picture>
            {img_xs && <source media="(max-width: 699px)" srcSet={img_xs} />}
            <img src={img} width={828} height={336} alt={alt} />
          </picture>
        </div>
      </div>
    </div>
  );
};
