import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  currentAnswers: string[];
}

const initialState: IState = {
  currentAnswers: [],
};

const answersSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addAnswer(state, action: PayloadAction<string>) {
      state.currentAnswers = [...state.currentAnswers, action.payload];
    },
    setAnswers(state, action: PayloadAction<string[]>) {
      state.currentAnswers = action.payload;
    },
    clearAnswers(state) {
      state.currentAnswers = [];
    },
  },
});

export const { addAnswer, setAnswers, clearAnswers } = answersSlice.actions;

export default answersSlice;
