import cn from 'classnames';
import { RESULT } from './data';
import { Footer } from '@/src/components/Footer';
import styles from './Final.module.scss';
import { BtnReplay } from '@/src/components/BtnReplay';
import { useAppSelector } from '@/src/hooks';
import { currentAnswersSelector } from '@/src/store/selectors/answers';
import { WebtiseLink } from '@/src/components/WebtiseLink/WebtiseLink';

export const Final = () => {
  const answers = useAppSelector(currentAnswersSelector);

  const getResult = (answersArr: string[]): string => {
    const firstAnswer = answersArr[0];
    const count = answersArr.filter((answer) => answer === firstAnswer).length;
    if (count >= 2) return firstAnswer;
    return answersArr.filter((answer) => answer !== firstAnswer)[0];
  };

  const resultId = getResult(answers);
  const data = resultId ? RESULT[resultId as keyof typeof RESULT] : RESULT['a'];

  const { title, text, img, alt } = data;

  return (
    <div className={cn(styles.final, 'appear')}>
      <div className={cn(styles.final_container, 'container')}>
        <BtnReplay classes={styles.btn_replay} />
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.result}>
              <h3 className={cn(styles.title, 'title_5')}>{title}</h3>
              <div className={styles.description}>
                {text?.map((string: string, i: number) => (
                  <p key={i}>{string}</p>
                ))}
              </div>
            </div>
            <div className={styles.cta}>
              <div className={cn(styles.cta_title, 'title_3')}>
                Вдохновились нашими героинями?
              </div>
              <div className={styles.cta_text}>
                <p>
                  Можете стать одной из&#160;них. Нужны
                  <span> </span>
                  <b>действующие права категории&#160;B</b>
                  <span> </span>
                  и&#160;
                  <b>не&#160;меньше 3&#160;лет водительского&#160;стажа</b>.
                  С&#160;остальным мы&#160;поможем!
                </p>
              </div>
              <WebtiseLink
                text="Стать партнёром Яндекс&#160;Про"
                classes={`${styles.cta_btn}`}
                analytics_action="results"
                analytics_label="bePartner"
              />
            </div>
          </div>
          <div className={styles.img}>
            <img src={img} width={900} height={900} alt={alt} />
          </div>
        </div>
      </div>
      <div className={cn(styles.footer_container, 'container')}>
        <Footer />
      </div>
    </div>
  );
};
