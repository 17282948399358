import cn from 'classnames';
// import styles from './WebtiseLink.module.scss';
import sendEventToCounters from '@/src/countersEvents.js';
import { HH_COMPANY_PAGE_LINK } from '../../consts';

type TProps = {
  text?: string;
  classes?: string;
  analytics_action: string;
  analytics_label: string;
};

export const WebtiseLink = ({
  text = 'Сотрудничать с\u00A0Яндекс\u00A0Про',
  classes,
  analytics_action = '',
  analytics_label = '',
}: TProps) => {
  const sendAnalytics = () => {
    sendEventToCounters({ action: analytics_action, label: analytics_label });
  };

  return (
    <a
      className={cn(classes, 'btn')}
      href={HH_COMPANY_PAGE_LINK}
      target="_blank"
      rel="noreferrer"
      onClick={sendAnalytics}
    >
      {text}
    </a>
  );
};
