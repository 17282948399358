import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  currentBranch: number;
}

const initialState: IState = {
  currentBranch: 0,
};

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setCurrentBranch(state, action: PayloadAction<number>) {
      state.currentBranch = action.payload;
    },
  },
});

export const { setCurrentBranch } = branchSlice.actions;

export default branchSlice;
