import { useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Swiper, SwiperSlide, SwiperClass, SwiperRef } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import cn from 'classnames';
import { DATA } from './data';
import { Card } from '@/src/components/Card';
import { Footer } from '@/src/components/Footer';
import styles from './Game.module.scss';
import { HeaderWithPagination } from '@/src/components/HeaderWithPagination';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/src/hooks';
import { currentStepSelector } from '@/src/store/selectors/step';
import { setCurrentStep } from '@/src/store/slices/stepSlice';
import { currentBranchSelector } from '@/src/store/selectors/branch';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import { addAnswer } from '@/src/store/slices/answersSlice';
import sendEventToCounters from '@/src/countersEvents.js';

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();
  const currentStep = useAppSelector(currentStepSelector);
  const currentBranch = useAppSelector(currentBranchSelector);

  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );
  const swiperRef = useRef<SwiperRef>(null);
  const [isRenderingNewQuestion, setIsRenderingNewQuestion] = useState(false);

  const data = DATA.filter(({ branch_id }) => branch_id === currentBranch)[0];

  const [currentData, setCurrentData] = useState(
    data.questions[currentStep - 1],
  );

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const isMobile = useMediaQuery('(max-width: 699px)');
  const isSmallHeight = useMediaQuery(
    '(min-width: 1100px) and (max-height: 800px)',
  );

  const isLastQuestion = useMemo(
    () => (data ? currentStep - 1 >= data.questions.length - 1 : false),
    [currentStep],
  );

  const sendAnalytics = () => {
    sendEventToCounters({
      action: `quest${currentStep + 1}`,
      label: data.branch_analytics_id,
    });
  };

  const handleClickOnCard = (resulId: string, index: number) => {
    dispatch(addAnswer(resulId));
    setSelectedAnswer(index);
    sendAnalytics();
  };

  const handleClickNextQuestion = () => {
    if (!isLastQuestion) {
      dispatch(setCurrentStep(currentStep + 1));
      setCurrentData(data.questions[currentStep]);
      setSelectedAnswer(null);
      setIsRenderingNewQuestion(true);
      swiperInstance?.slideTo(0);
      setTimeout(() => {
        setIsRenderingNewQuestion(false);
      }, 100);
    } else {
      changePage('final');
    }
  };

  return (
    <div className={cn(styles.game)}>
      <div className={cn(styles.game_container, 'container')}>
        <HeaderWithPagination
          shouldBlink={isRenderingNewQuestion}
          title={currentData.question}
          step={currentStep}
        />
        {(isMobile || isSmallHeight) && (
          <Swiper
            ref={swiperRef}
            onSwiper={setSwiperInstance}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            watchOverflow={true}
            slidesPerView={1.04}
            spaceBetween={10}
            loop={false}
            initialSlide={0}
            speed={50}
          >
            {currentData.answers.map((data, i: number) => {
              return (
                <SwiperSlide key={i}>
                  <Card
                    key={`${currentStep}${i}`}
                    cardClickHandler={() =>
                      handleClickOnCard(data.result_id, i)
                    }
                    btnClickHandler={handleClickNextQuestion}
                    classes={cn(
                      styles.card,
                      isRenderingNewQuestion ? 'invisible' : 'appear',
                    )}
                    isSelected={Boolean(
                      selectedAnswer !== null && selectedAnswer === i,
                    )}
                    isDisabled={Boolean(
                      selectedAnswer !== null && selectedAnswer !== i,
                    )}
                    index={i + 1}
                    data={data}
                    hasOutline
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {!isMobile && !isSmallHeight && (
          <div className={cn(styles.answers, 'appear')}>
            {currentData.answers.map((data, i: number) => {
              return (
                <Card
                  key={`${currentStep}${i}`}
                  cardClickHandler={() => handleClickOnCard(data.result_id, i)}
                  btnClickHandler={handleClickNextQuestion}
                  classes={styles.card}
                  isSelected={Boolean(
                    selectedAnswer !== null && selectedAnswer === i,
                  )}
                  isDisabled={Boolean(
                    selectedAnswer !== null && selectedAnswer !== i,
                  )}
                  index={i + 1}
                  data={data}
                  hasOutline
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={cn(styles.footer_container, 'container')}>
        <Footer />
      </div>
    </div>
  );
};
