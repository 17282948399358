import cn from 'classnames';
import styles from './PaginationItem.module.scss';

type TProps = {
  index: number;
  isActive?: boolean;
};

export const PaginationItem = ({ index, isActive = false }: TProps) => {
  return (
    <div
      className={cn(styles.pagination_item, {
        [styles.pagination_item_active]: isActive,
      })}
    >
      {index + 1}
    </div>
  );
};
