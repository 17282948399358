import img_a1 from './images/img_a1.svg';
import img_a2 from './images/img_a2.svg';
import img_a3 from './images/img_a3.svg';
import img_b1 from './images/img_b1.svg';
import img_b2 from './images/img_b2.svg';
import img_b3 from './images/img_b3.svg';
import img_c1 from './images/img_c1.svg';
import img_c2 from './images/img_c2.svg';
import img_c3 from './images/img_c3.svg';
import img_d1 from './images/img_d1.svg';
import img_d2 from './images/img_d2.svg';
import img_d3 from './images/img_d3.svg';
import img_e1 from './images/img_e1.svg';
import img_e2 from './images/img_e2.svg';
import img_e3 from './images/img_e3.svg';
import img_f1 from './images/img_f1.svg';
import img_f2 from './images/img_f2.svg';
import img_f3 from './images/img_f3.svg';
import img_g1 from './images/img_g1.svg';
import img_g2 from './images/img_g2.svg';
import img_g3 from './images/img_g3.svg';
import img_h1 from './images/img_h1.svg';
import img_h2 from './images/img_h2.svg';
import img_h3 from './images/img_h3.svg';

const questions = {
  1: 'Для\u00A0кого‑то личный транспорт\u00A0— просто средство передвижения. А\u00A0для\u00A0вас?',
  2: 'У вождения транспорта с\u00A0пассажиром\u00A0— свои особенности. Как\u00A0в\u00A0этом случае будете справляться с\u00A0неуверенностью?',
  3: 'Последний и\u00A0самый важный вопрос: чудеса\u00A0случаются?',
};

export const DATA = [
  {
    branch_id: 0,
    branch_analytics_id: 'ownWay',
    questions: [
      {
        question: questions[1],
        answers: [
          {
            result_id: 'a',
            title: 'Инструмент для\u00A0достижения целей',
            description: [
              'Современные водители не\u00A0боятся ставить далеко идущие цели. Например, Ксения из\u00A0Екатеринбурга копит на\u00A0загородный дом для своей семьи. С\u00A0Яндекс Про это проще: в\u00A0приложении есть возможность получать бонусы: карта районов с\u00A0высоким спросом, реферальные программы и\u00A0другие инструменты. А\u00A0ещё можно рассказать о\u00A0своей мечте у\u00A0себя в\u00A0профиле\u00A0— пассажиры видят это и\u00A0охотнее оставляют чаевые, потому что чувствуют связь с\u00A0водителем.',
            ],
            img: img_a1,
            alt: '',
          },
          {
            result_id: 'b',
            title: 'Ключ к\u00A0свободе',
            description: [
              'Свобода у\u00A0каждого своя. Для водителя Екатерины это\u00A0— не\u00A0зависеть от\u00A0начальников и\u00A0не\u00A0жертвовать общением с\u00A0детьми.',
            ],
            img: img_b1,
            alt: '',
          },
        ],
      },
      {
        question: questions[2],
        answers: [
          {
            result_id: 'a',
            title: 'Неуверенность? Не\u00A0знаю такого слова',
            description: [
              'Важно, чтобы все чувствовали такую же уверенность. Система в\u00A0Яндекс Про присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь. В\u00A0случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_a2,
            alt: '',
          },
          {
            result_id: 'b',
            title: 'Не стесняюсь просить помощи',
            description: [
              'А тут даже просить не\u00A0нужно: в\u00A0Яндекс Про система присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь. В\u00A0случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_b2,
            alt: '',
          },
        ],
      },
      {
        question: questions[3],
        answers: [
          {
            result_id: 'a',
            title: 'Если действительно к\u00A0ним стремиться',
            description: [
              'Лариса, водитель в\u00A0тарифе «Business» из\u00A0Екатеринбурга, думала, что никогда не\u00A0войдёт в\u00A0рейтинг лучших водителей. А\u00A0в\u00A0январе 2023 года получила заветный платиновый статус. Это не\u00A0чудеса, а\u00A0профессионализм!',
            ],
            img: img_a3,
            alt: '',
          },
          {
            result_id: 'b',
            title: 'Только с\u00A0искренними и\u00A0открытыми людьми',
            description: [
              'В сообществе такси каждый может найти своих\u00A0— тех, с\u00A0кого хочется брать пример, единомышленников, друзей и\u00A0даже любимых. Дружба с\u00A0другим водителем началась у\u00A0Марии с\u00A0смски и\u00A0продолжается уже несколько лет: «Каждый выход на\u00A0линию мы начинаем с\u00A0кофе у\u00A0автовокзала и\u00A0разъезжаемся, чтобы вечером за\u00A0ужином обсудить, как прошёл день».',
            ],
            img: img_b3,
            alt: '',
          },
        ],
      },
    ],
  },
  {
    branch_id: 1,
    branch_analytics_id: 'perfect',
    questions: [
      {
        question: questions[1],
        answers: [
          {
            result_id: 'c',
            title: 'Источник перемен, причем всегда к\u00A0лучшему',
            description: [
              'Для многих знакомство с\u00A0профессией водителя происходит в\u00A0период больших перемен. Инна из\u00A0Тюмени решила попробовать себя в\u00A0такси после выхода на\u00A0пенсию, а\u00A0Анастасия из\u00A0Москвы\u00A0— в\u00A0декрете. Они обе остались на\u00A0годы, потому что нашли любимое дело и\u00A0стабильный доход.',
            ],
            img: img_c1,
            alt: '',
          },
          {
            result_id: 'd',
            title: 'Яркий аксессуар\u00A0— как сумочка, но лучше!',
            description: [
              'Не все водители такси выполняют заказы на\u00A0своём авто\u00A0— многие берут машины в\u00A0таксопарке. Самовыражению это не\u00A0мешает: Анастасия из\u00A0Краснодара не\u00A0садится за\u00A0руль без набора любимых помад в\u00A0бардачке. Светлана из\u00A0Москвы всегда берёт с\u00A0собой вязание\u00A0— сделать пару рядов в\u00A0перерывах между заказами.',
            ],
            img: img_d1,
            alt: '',
          },
        ],
      },
      {
        question: questions[2],
        answers: [
          {
            result_id: 'c',
            title:
              'Просто следую правилам сервиса, больше ничего и\u00A0не\u00A0требуется',
            description: [
              'Придерживаться правил\u00A0— заботиться, чтобы всем было комфортно. В\u00A0приложении Яндекс Про они тоже есть: оно не\u00A0предлагает новичкам сложные заказы. Также система отслеживает нестандартные случаи: например, если водитель ушёл с\u00A0маршрута, с\u00A0ним сразу свяжется служба поддержки и\u00A0уточнит, нужна\u00A0ли помощь.',
              'В\u00A0случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_c2,
            alt: '',
          },
          {
            result_id: 'd',
            title:
              'Я ни о\u00A0чём таком и\u00A0не\u00A0думаю, просто действую',
            description: [
              'Чтобы вы могли спокойно выполнять заказы, о\u00A0хлопотах подумает система в\u00A0Яндекс Про. Она присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь.',
              'В\u00A0случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_d2,
            alt: '',
          },
        ],
      },
      {
        question: questions[3],
        answers: [
          {
            result_id: 'c',
            title: 'Чудеса повсюду, стоит лишь приглядеться',
            description: [
              'И\u00A0приумножаются, если ими поделиться. Екатерина, водитель в\u00A0тарифе «Детский», зовёт маленьких пассажиров «принцами» и\u00A0«принцессами». А\u00A0они читают ей стихи!',
            ],
            img: img_c3,
            alt: '',
          },
          {
            result_id: 'd',
            title: 'Женщина может быть кем угодно\u00A0— даже волшебницей!',
            description: [
              'А ещё рыбачкой, как Евгения из\u00A0Москвы, или пекарем, как Ксения из\u00A0Екатеринбурга. У\u00A0водителей нет ограничений в\u00A0том, как проводить свободное время.',
            ],
            img: img_d3,
            alt: '',
          },
        ],
      },
    ],
  },
  {
    branch_id: 2,
    branch_analytics_id: 'newHorizon',
    questions: [
      {
        question: questions[1],
        answers: [
          {
            result_id: 'e',
            title: 'Это буквально часть меня',
            description: [
              'Одним водителям, например, Ксении из\u00A0Екатеринбурга, комфортнее выполнять заказы на\u00A0своём авто\u00A0— всё привычно и\u00A0понятно. Другим такой вариант не\u00A0подходит. Если хочется оставить личный автомобиль для себя, можно взять другую машину для заказов в\u00A0надежном таксопарке\u00A0— таких много.',
            ],
            img: img_e1,
            alt: '',
          },
          {
            result_id: 'f',
            title: 'Один из\u00A0способов ощутить вкус жизни',
            description: [
              '«Я 10 лет не\u00A0видела, как разводят мосты, а\u00A0теперь наслаждаюсь\u00A0— всё лето вижу и\u00A0их, и\u00A0белые ночи»,\u00A0— Екатерина, водитель из\u00A0Санкт-Петербурга. Пожалуй, лучше и\u00A0не\u00A0скажешь.',
            ],
            img: img_f1,
            alt: '',
          },
        ],
      },
      {
        question: questions[2],
        answers: [
          {
            result_id: 'e',
            title: 'Не боюсь ошибок\u00A0— боюсь не\u00A0попробовать',
            description: [
              'Для вас сделали всё, чтобы пробовать было комфортно.',
              'В Яндекс Про система присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь.',
              'В случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_e2,
            alt: '',
          },
          {
            result_id: 'f',
            title: 'Жму на\u00A0педаль\u00A0— и\u00A0чувствую уверенность!',
            description: [
              'На вашем пути расчистили все препятствия\u00A0— смело двигайтесь вперед.',
              'В Яндекс Про система присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь.',
              'В случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_f2,
            alt: '',
          },
        ],
      },
      {
        question: questions[3],
        answers: [
          {
            result_id: 'e',
            title: 'Если не\u00A0испугаться крутого поворота',
            description: [
              'Раньше Наталья служила в\u00A0полиции, а\u00A0сейчас сотрудничает с\u00A0Яндекс Про, как и\u00A0её дочка Александра. Говорит, что чувствует себя счастливым человеком.',
            ],
            img: img_e3,
            alt: '',
          },
          {
            result_id: 'f',
            title: 'Если ловить волшебство каждого момента',
            description: [
              'Как Инна, водитель из\u00A0Тюмени. У\u00A0её пассажира было в\u00A0запасе время до вылета\u00A0— Инна устроила ему целую автоэкскурсию по своим любимым местам.',
            ],
            img: img_f3,
            alt: '',
          },
        ],
      },
    ],
  },
  {
    branch_id: 3,
    branch_analytics_id: 'usefull',
    questions: [
      {
        question: questions[1],
        answers: [
          {
            result_id: 'g',
            title: 'Верный помощник',
            description: [
              'Для многих водителей таким помощником стало приложение Яндекс Про. Юлия почти каждый день ездит на\u00A0работу в\u00A0другой город. Чтобы не\u00A0кататься вхолостую, Юлия берёт заказы в\u00A0удобных режимах «По делам» или «Домой».',
            ],
            img: img_g1,
            alt: '',
          },
          {
            result_id: 'h',
            title: 'Инструмент для самосовершенствования',
            description: [
              'Если водитель любит планирование, а\u00A0не\u00A0застой, и\u00A0хочет развиваться, можно перейти в\u00A0тариф по душе. Так сделали Екатерина из\u00A0Москвы, водитель в\u00A0тарифе «Детский», и\u00A0Лариса из\u00A0Екатеринбурга, которая выполняет заказы только в\u00A0«Business».',
            ],
            img: img_h1,
            alt: '',
          },
        ],
      },
      {
        question: questions[2],
        answers: [
          {
            result_id: 'g',
            title: 'В нужный момент внутренне собираюсь',
            description: [
              'Всё давно придумано за\u00A0вас\u00A0— так, чтобы было проще.',
              'В Яндекс Про система присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь.',
              'В случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_g2,
            alt: '',
          },
          {
            result_id: 'h',
            title: 'Полагаюсь на\u00A0смекалку и\u00A0советы друзей',
            description: [
              'В приложении Яндекс Про система умеет выручать. Она присматривает за\u00A0поездкой: новичку не\u00A0придёт сложный заказ, а\u00A0в\u00A0случае отклонения от\u00A0маршрута или долгого простоя машины на\u00A0связь сразу выйдет служба поддержки и\u00A0уточнит, нужна ли помощь.',
              'В случае недопонимания с\u00A0пассажиром можно сделать и\u00A0отправить поддержке запись разговора\u00A0— специалисты найдут решение.',
            ],
            img: img_h2,
            alt: '',
          },
        ],
      },
      {
        question: questions[3],
        answers: [
          {
            result_id: 'g',
            title: 'Ещё как, если самой их творить!',
            description: [
              'Так даже лучше!',
              'Вот Евгения, водитель из\u00A0Москвы, успевает и\u00A0с\u00A0детьми на\u00A0рыбалку, и\u00A0зимой на\u00A0лыжах, и\u00A0на новую машину откладывать. А\u00A0ещё волосы в\u00A0красный\u00A0— ой, уже в\u00A0розовый,\u00A0— покрасить.',
            ],
            img: img_g3,
            alt: '',
          },
          {
            result_id: 'h',
            title: 'Конечно, случаются, если не\u00A0быть равнодушной',
            description: [
              'Водители друг друга в\u00A0беде не\u00A0бросают. Екатерина, водитель из\u00A0Подмосковья, вместе с\u00A0товарищами организовала группу взаимопомощи и\u00A0хороших дел\u00A0— они вытаскивают машины водителей своего района из-под снега зимой и\u00A0даже помогают приютам для животных.',
            ],
            img: img_h3,
            alt: '',
          },
        ],
      },
    ],
  },
];
