import { configureStore } from '@reduxjs/toolkit';
import stepSlice from './slices/stepSlice';
import branchSlice from './slices/branchSlice';
import answersSlice from './slices/answersSlice';

export const store = configureStore({
  reducer: {
    step: stepSlice.reducer,
    branch: branchSlice.reducer,
    anwers: answersSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
